import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  scrollBehavior(to) {
    // always scroll to top
    if (to.hash && to.hash !== "#") {
      return {
        el: to.hash,
        top: 100
      };
    } else {
      return {
        top: 0,
        behavior: "auto"
      };
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView
    },
    {
      path: "/about",
      name: "about",

      component: () => import("../views/AboutView.vue")
    },
    {
      path: "/nexen-blogs",
      name: "blog",
      component: () => import("../views/BlogView.vue")
    },
    {
      path: "/:notFound",
      component: () => import("../views/404.vue")
    },
    {
      path: "/nexen-blog/:slug",
      name: "blog-post",
      component: () => import("../views/BlogPost.vue")
    },
    {
      path: "/why-nexen",
      name: "why-nexen",
      component: () => import("../views/WhyNexenView.vue")
    },
    {
      path: "/contactSales",
      name: "contactSales",
      component: () => import("../views/BookAsDemo.vue")
    },
    {
      path: "/clients",
      name: "clients",
      component: () => import("../views/ClientsView.vue")
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: () => import("../views/ContactUs.vue")
    },
    {
      path: "/security",
      name: "security",
      component: () => import("../views/SecurityView.vue")
    }
  ]
});

export default router;
