<template>
   <section class="q-nexen-android-section" style="padding-bottom: 0px !important">
    <div class="q-section">
      <div class="q-layout-container">
        <div class="q-layout-row">
          <div class="q-col-sm-12 q-col-md-6 q-col-lg-7 q-col-xl-7 q-display-flex-column flow-center">
            <img class="android-img" src="./../assets/images/android.jpg" alt="android screenshot" />
          </div>
          <div class="q-col-sm-12 q-col-md-6 q-col-lg-5 q-col-xl-5 q-display-flex-column flow-center hero-container">
            <span class="q-dirt q-swing q-animatable" id="q-arch-triangle"
              style="width: 20px !important; top: 0%; height: auto">
              <img src="./../assets/images/dirt-cross.svg" alt="triangle" style="width: 20px" />
            </span>
            <span class="q-dirt q-hide-on-small-screens q-throw q-dirt-arch q-run q-animatable" id="q-arch-dirt"
              style="width: 40px !important; height: auto; top: 90% !important; right: 50%">
              <img src="./../assets/images/dirt-triangle.svg" alt="arch" style="width: 40px" />
            </span>
            <h1 class="subtext">
              <span class="gw-hightlight">Revolutionize   </span>your SACCO Operations

            </h1>
            <p class="subtext">
              Experience unparalleled uptime alongside seamless SACCO and member experiences with the Nexen Mobile App.


            </p>
            <div class="q-button-group q-display-flex-row" style="width: 100%">
              <a style="margin: 0 !important; margin-top: 32px !important" href="https://drive.google.com/file/d/1WBy7Q5cUzvKyX446WS6_Uwi9-hm7Ng_-/view?usp=sharing"
                class="q-button-fill q-button q-button-large elevate-1 q-download-file-button q-display-flex flow-center q-center"><span
                  style="">Click here to Download The App</span>
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </section>
</template>
<script>


export default {
    name: "Android-section",
  
}
</script>
<style scoped>
.android-img{
  max-width: 80%;
  border-radius:1.5rem;
  border: 1px solid rgba(0,0, 0,.12);
  box-shadow: 8.0px 16.0px 16.0px hsl(0deg 0% 0% / 0.25);
 margin-top: 00px;
margin-bottom: 2rem;
  
}
.q-nexen-android-section{
position: relative;
}
.q-nexen-android-section::before{
background-color: #094333;
content: " ";
bottom: 0;
position: absolute;
width: 100%;
height: 50%;

}
.q-layout-row{
  overflow: hidden;
  background-color: #fcfdfc ;
  border-radius:2rem;
  padding: 1rem;
}
@media (min-width: 768px) {

  .android-img{
  max-width: 70%;
  border-radius: 16px;




  
  
}
}
@media (min-width: 992px) {
  .q-layout-row{

border-radius: 2rem;
padding: 2rem;
}
.android-img{
  max-width: 30%;
  border-radius: 8px;

  transform: scale(2);
 margin-top: 0px;
 margin-bottom: 0rem;
  
  
}
}
@media (min-width: 1200px) {
  .q-layout-row{

border-radius: 2rem;
padding: 4rem;
}
  .android-img{
  max-width: 30%;
  border-radius: 16px;

  transform: scale(2);
 margin-top: 200px;
 margin-bottom: 0rem;
  
  
}
}
</style>
