<template>
  <header>
    <div class="wrapper">
      <!--Small screen div-->
      <nav class="q-item-blur q-nav-small">
        <div class="q-nav-content-overlay q-display-flex-column">
          <div class="q-nav-header-container q-display-flex-row q-align-stretch">
            <div class="q-nav-controls-container">
              <a class="q-navbar-toggler q-display-flex-column q-center" href="javascript:void(0);">
                <span class="ri-menu-line"></span>
              </a>
            </div>
            <div class="q-nav-controls-container q-display-flex q-flex-center-items q-center">
              <a href="/" class="q-nav-logo-container q-display-flex-column q-flex-center-items q-center">
                <!--App Icon Begin-->
                <img src="./assets/images/nexentech_logo.png" style="width: 60px" alt="nexentech logo" />
                <!--App Icon End--></a>
            </div>

            <div class="q-nav-controls-container q-display-flex q-flex-center-items q-center">
              <div class="q-display-flex q-flex-center-items q-center">
                <div id="${this._authButtonsId}"
                  class="q-auth-buttons-container q-display-flex-row q-center q-flex-center-items">
                  <a class="q-button q-button-fill q-display-flex-row q-center q-flex-center-items "
                    href="/contactSales" style="width: 120px !important;">
                    <span>
                      Book Demo</span></a>
                </div>
              </div>
            </div>
          </div>

          <ul class="q-nav-ul q-small-nav-ul q-display-flex-column">
            <li>
              <RouterLink @click="closeNav" to="/clients">Clients</RouterLink>
            </li>
            <div class="dropdown-small" v-for="link in links" :key="link.id">
              <li>
                <router-link :key="link.id" to="/why-nexen" class="dropbtn-small">
                  {{ link.text }}
                </router-link>
              </li>
              <div class="dropdown-content-small">
                <li>
                  <router-link @click="closeNav" v-for="subLink in link.subLinks" :key="subLink.id"
                    :to="{ name: 'why-nexen', 'hash': `#${subLink.page}` }">

                    {{ subLink.text }}
                  </router-link>
                </li>
              </div>

            </div>
            <li>
              <RouterLink @click="closeNav" to="/nexen-blogs">Blog</RouterLink>
            </li>

            <li>
              <RouterLink @click="closeNav" to="/security">Security</RouterLink>
            </li>

            <li>
              <RouterLink @click="closeNav" to="/about">About</RouterLink>
            </li>
            <li>
              <RouterLink @click="closeNav" to="/contact-us">Contact</RouterLink>
            </li>
            <li class="q-button-fill q-button" style="width: 100%; background-color: transparent"></li>
          </ul>
        </div>
      </nav>

      <!--large screen nav-->

      <nav class="q-item-blur q-nav-large " id="navBar">
        <div class="q-nav-content-overlay">
          <div class="q-section">
            <div class="q-nav-header-container q-display-flex-row q-align-stretch">
              <div class="q-nav-controls-container q-display-flex q-center q-flex-center-items">
                <ul
                  class="q-nav-ul q-display-flex-row q-item-animatable q-align-self-right q-flex-center-items q-center q-nav-index-url"
                  style="margin: 0px !important">
                  <li style="padding: 0" class="q-display-flex-row q-flex-center-items">
                    <a href="/" class="q-nav-logo-container q-display-flex-row q-flex-center-items q-flex-end">
                      <!--App Icon Begin-->
                      <img src="./assets/images/nexentech_logo.png" style="width: 90px" alt="nexemicrocredit logo" />
                      <!--App Icon End-->
                    </a>
                  </li>
                </ul>
              </div>
              <div class="q-nav-controls-container q-display-flex-row q-center q-flex-center-items">
                <RouterLink to="/">Home</RouterLink>
                <RouterLink to="/about">About</RouterLink>

                <div class="dropdown q-animatable" v-for="link in links" :key="link.id">
                  <router-link :key="link.id" to="/why-nexen" v-on:click.prevent="showResponder()" class="dropbtn">
                    {{ link.text }}
                  </router-link>

                  <div class="dropdown-content elevate-1">
                    <router-link v-for="subLink in link.subLinks" :key="subLink.id"
                      :to="{ name: 'why-nexen', 'hash': `#${subLink.page}` }">
                      {{ subLink.text }}
                      <p :class="showCaption(subLink.hasCaption)" class="link-caption">{{ subLink.caption }}</p>
                    </router-link>

                  </div>

                </div>
                <RouterLink to="/clients">Clients</RouterLink>
                <!-- <RouterLink to="/nexen-blogs">Blog</RouterLink> -->
                <RouterLink to="/security">Security</RouterLink>

                <RouterLink to="/nexen-blogs">Blog</RouterLink>

                <RouterLink to="/contact-us">Contact</RouterLink>
              </div>
              <div class="q-nav-controls-container q-auth-container q-display-flex q-center">
                <div class="q-nav-auth-container q-display-flex q-center flow-center" style="height: var(--nav-height)">
                  <div class="q-auth-buttons-container q-display-flex-row flow-center q-center q-flex-center-items">

                    <a href="/contactSales" class="q-button q-button-fill q-display-flex flow-center" style="">
                      <span>Book A Demo</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>

  <RouterView :key="$route.fullPath" />

</template>
<script>

import $ from 'jquery'
var $q_page_nav = undefined
var $q_page_nave_toggler = undefined
var is_navbar_open = false
var isScrolling = false;
export default {
  data() {
    return {


      links: [
        {
          key: "",
          page: './images/bridge_bidding_brown.webp',
          text: "Why Nexen",
          subLinks: [

            {
              id: 0,
              text: 'Mission',
              page: 'mission',
              caption: "",
              hasCaption: false
            },
            {
              id: 1,
              text: 'Vision',
              page: 'vision',
              caption: "",
              hasCaption: false
            },

            {
              id: 3,
              text: 'Core competencies',
              page: 'competences',
              caption: ""
              ,
              hasCaption: false
            },
          ]
        },


      ],

    }
  },
  mounted: function () {
    $q_page_nav = $('nav')
    $q_page_nave_toggler = $('.q-navbar-toggler')
    $q_page_nave_toggler.click()

    $q_page_nave_toggler.on('click', () => {
      this.toggleNav()
    })
    $(".dropbtn-small").on('click', (e) => {

      e.preventDefault()
      var el = $(".dropdown-content-small")
      $("items").css("left");
      if (el.css("display") == "block") {
        el.css({ "display": "none   " })
      } else
        el.css({ "display": "block" })
    })

    $('#closeElement').on('click', () => {
      this.closeNav()
    })

    document.addEventListener("DOMContentLoaded", () => {

      this.dealWithScrolling()
    }, false);
    window.addEventListener("scroll", () => { this.dealWithScrolling() }, false);


  },

  methods: {
    dealWithScrolling: function () {
      //scroll(loop)

      if (!isScrolling) {
        window.requestAnimationFrame(function () {
          var header = document.querySelector("#app");
          var navBar = document.querySelector("#navBar");


          var header_position = header.getBoundingClientRect();

          if (header_position.top < -26) {

            navBar.classList.remove("q-transparent-light-content");

            // navLinks.classList.add("dark-color");
            navBar.classList.add("q-light-bg-dark-content");
          }
          else {
            navBar.classList.remove("q-light-bg-dark-content");

            // navLinks.classList.add("light-color");
            navBar.classList.add("q-transparent-light-content");
          }
          isScrolling = false;
        });
      }
      isScrolling = true;
    },


    showCaption(show) {
      return !show ? "hide-trigger" : ""
    },
    toggleNav: function () {

      var element = $('.q-navbar-toggler span')
      is_navbar_open = element.hasClass("ri-close-large-line")

      this.addClassToItem($q_page_nav, 'q-item-animatable')
      var navCss = {
        height: is_navbar_open ? '56px' : '100%',
        'overflow-y': is_navbar_open ? 'hidden' : 'auto',
        'overflow-x': 'hidden'
      }

      if (!is_navbar_open) {

        this.removeClassFromItem(element, 'ri-menu-line')
        this.addClassToItem(element, 'ri-close-large-line')
      } else {
        this.addClassToItem(element, 'ri-menu-line')
        this.removeClassFromItem(element, 'ri-close-large-line')

      }
      $q_page_nav.css(navCss)
      //$(".q-nav-logo-container").css(nav_logo_css)

      is_navbar_open = !is_navbar_open


    },
    showResponder: function () {


    },
    closeNav: function () {
      //is_navbar_open = true;


      this.addClassToItem($('#openElement'), 'hide-trigger')
      this.removeClassFromItem($('#closeElement'), 'hide-trigger')
      var navCss = {
        height: '56px',
        'overflow-y': 'hidden',
        'overflow-x': 'hidden'
      }
      var element = $('.q-navbar-toggler span')

      this.addClassToItem(element, 'ri-menu-line')
      this.removeClassFromItem(element, 'ri-close-large-line')


      $q_page_nav.css(navCss)
      is_navbar_open = false

    },
    openNav: function () {
      this.addClassToItem($('#closeElement'), 'hide-trigger')
      this.removeClassFromItem($('#openElement'), 'hide-trigger')
      //is_navbar_open = true;
      var element = $('.q-navbar-toggler span')
      is_navbar_open = element.hasClass("ri-close-large-line")

      this.addClassToItem($q_page_nav, 'q-item-animatable')
      var navCss = {
        height: '100%',
        'overflow-y': 'auto',
        'overflow-x': 'hidden'
      }

      this.removeClassFromItem(element, 'ri-menu-line')
      this.addClassToItem(element, 'ri-close-large-line')

      $q_page_nav.css(navCss)

    },
    addClassToItem: function (item, _class) {
      if (!item.hasClass(_class)) {
        item.addClass(_class)
      }
    },

    removeClassFromItem: function (item, _class) {
      if (item.hasClass(_class)) {
        item.removeClass(_class)
      }
    }
  }
}
</script>
<style scoped>
.q-light-bg-dark-content {
  background-color: rgb(255, 255, 255, .9) !important;
  border-bottom: 1px solid rgb(0, 0, 0, .12)
}

.q-transparent-light-content {
  background-color: transparent !important;
}

.router-link-active {
  background-color: transparent;
  color: var(--gw_primary_color);
}

.hide-trigger {
  display: none !important;
}

nav a {
  padding: 8px 16px;
  color: var(--gw_text_color);

}

.q-nav-ul {
  width: 100%;
  height: 100%;
}


.dropdown {
  float: left;
  overflow: hidden;
  width: fit-content;
}

.dropdown-small {
  width: 100%;

}

nav a:hover {

  /* border-radius: 1px; */
  color: var(--gw_primary_color);
}

.dropdown .dropbtn {
  margin: 0;
  width: 140px;
  display: block;
  position: relative;
  color: var(--gw_text_color) !important;
}

.dropbtn-small {
  color: var(--gw_text_color) !important;
}

.link-caption {
  background-color: #eef9ee;
  border-radius: 16px;
  padding: 16px;
  margin: 0;
  margin-top: 8px;
  font-size: 1em;

}

.dropbtn-small::after,
.dropbtn::after {
  content: "\EA4E";
  font-family: 'remixicon' !important;
  position: absolute;
  font-size: 1.3em;
  margin-left: 8px;
}



.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 250px;

  z-index: 1;
  border-radius: 8px;
}

.dropdown-content-small {
  display: none;
  background-color: transparent !important;
  width: 100%;
  z-index: 1;
  border-radius: 0;
}

.dropdown-content-small li {
  background-color: transparent !important;
  padding: 0;
  margin: 0;
}

.dropdown-content-small li a {
  padding: 12px 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 16px;

  text-align: left;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}


.dropdown-content a:hover {
  background-color: #00ab0014;
  border-radius: 1px;
}


.dropdown:hover .dropdown-content {
  display: block;
}
</style>
